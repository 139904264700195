.tag-table-wrapper {
    width: 100%;
}

.tag-table,
.tag-table .tbody,
.tag-table .scroll-container,
.tag-table .table-list-rows,
.tag-table .table-list-rows > * {
    height: 100% !important;
    width: 100% !important;
}

.tag-table .table-list-rows::-webkit-scrollbar {
    display: none;
}

.tag-table .table-list-rows {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.tag-table .scroll-decoration-top,
.tag-table .scroll-decoration-bottom {
    margin: 0 -1rem !important;
    width: calc(100% + 2rem) !important;
}

.tag-table .tr {
    width: auto !important;
}

.tag-table label {
    display: inline !important;
    user-select: none;
    cursor: pointer;
}

.tag-table-status {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
