.tag-table-title {
    align-items: center;
}

.tag-table-title .title-text {
    flex-grow: 1;
}

.tag-table-title .help-link {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-right: 64px;
    font-family: 'skufont-regular';
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

.admin-tags {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 8px;
}

.admin-tags .admin-tags-actions {
    flex: 0 1 auto;
    width: 100%;
}

.admin-tags .admin-tags-actions input {
    margin-bottom: 0 !important;
}

.admin-tags .admin-tags-content {
    flex: 1 0 auto !important;
}

.admin-tags-actions .filter-tags {
    width: 100%;
}
