h1,
h2,
h3,
h4,
h5 {
  font-family: 'skufont-demibold', "museo-sans", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif !important; }

#root {
  background: #edf4f7; }

.tabbed {
  display: flex !important;
  flex-direction: column; }
  .tabbed .popup-header {
    padding: 20px !important; }
  .tabbed .popup-content {
    flex-grow: 1;
    height: unset !important; }
  .tabbed .module-tab {
    display: block;
    color: #00B1C8;
    padding: 10px 0 10px 40px;
    font-weight: 600;
    list-style: none; }
    .tabbed .module-tab.active {
      background-color: #00B1C8;
      color: white; }
  .tabbed .align-with-select {
    margin-top: 8px !important; }

.tabbed .add-button,
.non-tabbed .add-button {
  border: none !important;
  background: none !important;
  color: #00B1C8 !important; }

.centered-checkbox span {
  left: unset; }

button.white-button,
button.lighter-button {
  border-radius: 10px !important; }

button.white-button {
  border-color: #00A0B6 !important;
  color: #00A0B6 !important;
  background: unset !important; }

button.lighter-button {
  border-color: #E1F7FA !important;
  background-color: #E1F7FA !important;
  color: #00A0B6 !important; }

.no-margin-bottom {
  margin-bottom: 0 !important; }

.align-self-center {
  align-self: center; }

.Toastify__toast-container {
  z-index: 999999; }

.section-title {
  font-weight: bold !important;
  margin-top: 20px !important; }

.tile-summary-content {
  font-size: 0.875rem !important;
  color: #899CA9; }

/* Admin billing page where we show shop fees for each billing period */
.shop-fees .fee-box {
  background: white;
  padding: 32px;
  border: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 12px; }
  .shop-fees .fee-box .header-date,
  .shop-fees .fee-box .header-fee {
    font-size: 18px;
    font-weight: bold;
    color: #5ba0b4;
    display: inline-block;
    width: 50%; }

.shop-fees .fee-box.selected .header-fee,
.shop-fees .fee-box.selected .header-date {
  color: #c03d68;
  margin-bottom: 32px; }

.shop-fees .fee-box:hover .header-fee,
.shop-fees .fee-box:hover .header-date {
  color: #c03d68; }

.shop-fees .fee-box.selected {
  cursor: default;
  padding: 32px; }

.shop-fees .header-fee {
  text-align: right; }

.shop-fees .outer-table tbody {
  border: none; }

.shop-fees .outer-table thead {
  background: none;
  border: none; }

.shop-fees .outer-table td,
.shop-fees .outer-table th {
  background: none;
  padding: 0.2rem 0.625rem 0.2rem; }

.shop-fees .outer-table td:first-child {
  padding-left: 0; }

.shop-fees .outer-table td:last-child {
  padding-right: 0; }

.shop-fees .outer-table .outer-row:hover {
  background: #edf2f5;
  color: #5ba0b4; }

.shop-fees .outer-table .selected {
  font-weight: bold; }
  .shop-fees .outer-table .selected td.invoice-count:after {
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #6E8A92;
    content: "";
    display: block;
    margin: 2px -6px -3px auto; }

.shop-fees .inner-table {
  background: #6E8A92;
  font-size: .8rem;
  margin-top: 8px;
  margin-bottom: .5rem; }
  .shop-fees .inner-table tbody,
  .shop-fees .inner-table thead {
    background: transparent;
    color: white; }
  .shop-fees .inner-table thead {
    border-bottom: 1px solid rgba(255, 255, 255, 0.09); }
  .shop-fees .inner-table tbody {
    background: rgba(255, 255, 255, 0.078); }
  .shop-fees .inner-table td:first-child {
    padding-left: 0.625rem; }
  .shop-fees .inner-table td:last-child {
    padding-right: 0.625rem; }
  .shop-fees .inner-table tr:hover {
    background: rgba(255, 255, 255, 0.18); }
