.tags-popup-content {
    height: 100% !important;
    overflow-y: visible !important;
}

/* Force blank titles to occupy the same amount of space */
.tabbed h2:empty::before,
.non-tabbed h2:empty::before {
    display: inline-block;
    content: "";
}
