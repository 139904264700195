.tag-label {
    display: flex;
    height: 37px;
    border-radius: 100px;
    padding: 0px 13px !important;
    justify-content: center !important;
    align-items: center !important;
    color: var(--color-neutrals-10);
    background: var(--secondary-4-navy-600-f-6-ea-6, #0F6EA6);
}

.selectable-tag-label > * {
    height: 43px;
    align-items: center;
    margin: 0 !important;
    padding: 16px !important;
}
