.no-tags {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.no-tags .no-tags-content {
    display: flex;
    gap: inherit;
    flex-direction: inherit;
    overflow: scroll;
}

.no-tags .no-tags-info,
.no-tags .no-tags-cta {
    padding: 0 64px !important;
    flex-direction: column !important;
}

.no-tags .no-tags-info ul {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    gap: 1rem;
}

.no-tags .no-tags-cta {
    flex: 1 0 auto !important;
    width: fit-content;
}

.no-tags .no-tags-actions {
    margin-top: auto !important;
    flex-wrap: wrap-reverse !important;
    gap: 16px 24px;
}

.no-tags .no-tags-actions input {
    margin-bottom: 0 !important;
}

.no-tags .arrow-container {
    position: absolute;
}

.no-tags .info-button {
    background: var(--color-white, #FFF) !important;
    color: var(--color-primary1-70, #00A0B6) !important;
}

.no-tags .info-button svg path:last-child {
    fill: var(--color-primary1-70, #00A0B6) !important;
}
