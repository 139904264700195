.delete-tags {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.delete-tags-title {
    gap: 16px;
}

.delete-tags-alert, .delete-tags-actions {
    flex: 0 1 auto !important;
}

.delete-tags-alert > * {
    width: 100%;
    padding: 16px !important;
}

.delete-tags-actions {
    gap: 8px;
    margin-left: auto !important;
    margin-top: auto !important;
}

.tag-container {
    gap: 8px;
    overflow-y: scroll;
}

.delete-overlay {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
}

.delete-indicator {
    gap: 16px;
    align-items: center !important;
}
