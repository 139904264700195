.add-tag {
    display: flex;
    position: relative;
    align-items: center;
    flex-wrap: wrap-reverse;
}

.no-highlight {
    column-gap: 0.5rem;
}

.with-highlight {
    --highlight-padding: calc(1rem - 6px);
    gap: var(--highlight-padding) calc(0.5rem + var(--highlight-padding));;
}

.add-tag-controls {
    display: flex;
    gap: 0.5rem;
    white-space: nowrap;
}

.add-tag .add-tag-input {
    width: 155px !important;
}

.add-tag .add-tag-error {
    color: var(--color-secondary1-60);
}

.add-tag .button-highlight {
    position: absolute;
    left: calc(-1 * var(--highlight-padding));
    bottom: calc(-1 * var(--highlight-padding));
    width: 287px;
    height: 60px;
    border-radius: 5px;
    background: var(--color-primary1-30, #A6F4FF);
    z-index: -1;
}
