.merge-tags {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.merge-tags-title {
    gap: 16px;
}

.merge-tags-alert, .merge-tags-actions {
    flex: 0 1 auto !important;
}

.merge-tags-alert > * {
    width: 100%;
    padding: 16px !important;
}

.merge-tags-actions {
    gap: 8px;
    margin-left: auto !important;
    margin-top: auto !important;
}

.tag-container {
    gap: 8px;
    overflow-y: scroll;
}

.merging-overlay {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
}

.merging-overlay ~ * {
    filter: grayscale(1);
    pointer-events: none;
}

.merging-indicator {
    gap: 16px;
    align-items: center !important;
}
